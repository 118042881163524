import React, { useState } from "react";
import axios from "axios";
import { Layout, Col, Row, Form, Input, Button, message } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { navigate } from "../utils";

import HeaderPatinette from "../components/header";
import FooterPatinette from "../components/footer";

const { Content } = Layout;

export default function DefineRiderAccess() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  function handleSubmit({ email }) {
    // values.password et values.password2
    setIsLoading(true);
    axios
      .post(process.env.GATSBY_API_URL + "/ask-reset-password", { email })
      .catch((e) => {
        if (e.response.data.error.includes("already reset")) {
          message.error(
            "Une demande de réinitialisation a déjà été initiée. Merci de consulter votre boîte email"
          );
          return
        }
        message.error("Erreur lors de la communication : " + e.message);
      })
      .then(() => {
        message.info(
          "Un email de réinitialisation de mot de passe a été envoyé"
        );
        navigate("/login-mon-espace-rider");
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  return (
    <Layout className="centered-box">
      <HeaderPatinette />
      <Content className="content">
        <Row type="flex" justify="center">
          <Col lg={16} xs={24} className="main">
            <h2>Réinitialiser le mot de passe</h2>
            <p>Merci de renseigner votre email:</p>
            <Row>
              <Col
                lg={{ span: 10, offset: 7 }}
                xs={24}
                style={{ textAlign: "center" }}
              >
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  className="reset-password-form"
                >
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Merci de saisir votre email",
                      },
                    ]}
                  >
                    <Input
                      prefix={
                        <UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />
                      }
                      placeholder="Email"
                    />
                  </Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    style={{ marginTop: "2em" }}
                    loading={isLoading}
                  >
                    Réinitialiser mon mot de passe
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <FooterPatinette />
    </Layout>
  );
}
